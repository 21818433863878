var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "px-2 justify-content-center" },
    [
      _c(
        "b-col",
        { staticClass: "card-content mt-3", attrs: { cols: "12", lg: "6" } },
        [
          _c("h4", { staticClass: "primary fw-bold text-center" }, [
            _vm._v("Queremos confirmar que seas tú")
          ]),
          _c("p", { staticClass: "mt-4 fs-14", attrs: { align: "justify" } }, [
            _vm._v(
              " Enviamos un código OTP a tu número de celular registrado terminado en ******"
            ),
            _c("strong", [_vm._v(_vm._s(_vm.celular))]),
            _vm._v(
              " ingrésalo en el siguiente campo para validar tu identidad: "
            )
          ]),
          _c(
            "b-row",
            { staticClass: "pb-3" },
            [
              _c(
                "b-col",
                { staticClass: "text-center", attrs: { cols: "12" } },
                [
                  _c("p", { staticClass: "fs-12 primary fw-bold mb-1" }, [
                    _vm._v(" Ingresa aquí el código que llega a tu celular ")
                  ]),
                  _c("i", {
                    staticClass: "las la-arrow-down icon-menu primary py-1"
                  }),
                  _c(
                    "div",
                    { staticClass: "mb-2 content-center" },
                    [
                      _c("b-form-input", {
                        staticClass: "w-input-code",
                        attrs: {
                          state: _vm.$v.codigo.$dirty
                            ? !_vm.$v.codigo.$error
                            : null
                        },
                        on: { keypress: _vm.onlyNumber },
                        model: {
                          value: _vm.$v.codigo.$model,
                          callback: function($$v) {
                            _vm.$set(_vm.$v.codigo, "$model", $$v)
                          },
                          expression: "$v.codigo.$model"
                        }
                      })
                    ],
                    1
                  )
                ]
              ),
              _c(
                "b-col",
                { staticClass: "text-center", attrs: { cols: "12" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "danger" },
                      on: {
                        click: function($event) {
                          return _vm.validarCodigo()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "las la-check-circle" }),
                      _vm._v(" Ingresar ")
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "mx-2",
                      attrs: {
                        variant: "outline-danger",
                        disabled: _vm.contador > 0
                      },
                      on: {
                        click: function($event) {
                          return _vm.reenviarCodigo()
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "las la-paper-plane" }),
                      _vm._v(
                        " Reenviar Código " +
                          _vm._s(
                            _vm.contador > 0 ? "(" + _vm.contador + ")" : ""
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("p", { staticClass: "mt-2 fs-14", attrs: { align: "justify" } }, [
            _vm._v(
              " Si no es tu número registrado o cambiaste de línea de celular, comunícate con nosotros y actualiza tus datos a través de nuestras líneas de Servicio al Cliente: "
            )
          ]),
          _c("p", { staticClass: "mt-2 mb-0 primary" }, [
            _c("i", { staticClass: "las la-phone dark" }),
            _vm._v(" " + _vm._s(_vm.numeroUnicoColombia))
          ]),
          _c("p", { staticClass: "primary" }, [
            _c("i", { staticClass: "lab la-whatsapp color-whatsapp" }),
            _vm._v(" " + _vm._s(_vm.numeroWhastappCartera))
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }